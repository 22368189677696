/* hero.module.css */

.hero {
	position: relative;
	overflow: hidden;
	padding: 4rem 0;
	height: 100vh; /* Use full height of viewport */
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.hero-background {
	margin-top: -40px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) skewY(-5deg);
	width: 100%;
	height: 100%;
	padding: 2rem;
	background: linear-gradient(
		135deg,
		rgba(var(--background-start-rgb), 0.8),
		rgba(var(--background-end-rgb), 0.8)
	);
	z-index: -1;
}

.hero-container {
	max-width: 1280px;
	margin: 0 auto;
	padding: 0 1rem;
}

.hero-content {
	position: relative;
	z-index: 1;
	text-align: center;
	color: rgb(var(--foreground-rgb));
	padding-bottom: 40px;
}

.hero-inner {
	max-width: 800px;
	margin: 0 auto;
}

.hero-title {
	font-size: 3rem;
	font-weight: bold;
	margin-bottom: 1.5rem;
}

.hero-highlight {
	color: rgb(59, 130, 246);
}

.hero-description {
	font-size: 1.25rem;
	margin-bottom: 3rem;
	padding: 0 1rem; /* Added padding to ensure text fits within the background */
}

.hero-buttons {
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin-top: 2rem;
	z-index: 1; /* Ensure the buttons are above other elements */
	position: relative;
	top: -2rem;
}

.hero-cta {
	display: inline-block;
	padding: 0.75rem 1.5rem;
	font-size: 1.125rem;
	font-weight: bold;
	text-decoration: none;
	background: linear-gradient(
		135deg,
		#ffc107,
		#ffdb4d
	); 
	/* Yellow gradient for buttons */
	border-radius: 0.375rem;
	transition: background-color 0.3s ease;
	width: 230px; /* Fixed width for uniform button sizes */
	text-align: center;
	color: black;
}

.hero-cta:hover {
	background: linear-gradient(
		135deg,
		#ffbf00,
		#e6ac00
	); /* Slightly darker yellow for hover effect */
}


.hero-cta-arrow {
	display: inline-block;
	margin-left: 0.5rem;
	transition: transform 0.3s ease;
}

.hero-cta:hover .hero-cta-arrow {
	transform: translateX(0.25rem);
}

@media (min-width: 768px) {
	.hero-buttons {
		flex-direction: row;
	}
}

@media (max-width: 1028px) {
	.hero-background {
		width: 100%;
		padding: 2rem;
		margin-top: 0;
	}
}

@media (max-width: 767px) {
	.hero-container {
		padding-top: 40px;
	}
	.hero {
		margin-top: -100px;
	}
	.hero-title {
		margin: 0 auto;
		font-size: 2rem;
		width: 80%;
		padding-top: 20px;
		margin-top: 20px;
	}
	.hero-description {
		font-size: 1rem;
		margin: 0 auto;
		width: 90%;
	}
	.hero-buttons {
		flex-direction: column; /* Stack buttons as column on mobile devices */
		gap: 0.5rem; /* Adjust gap for better spacing */
		top: 2rem;
		padding-top: 30px;
		margin: 0 auto;
	}
	.hero-background {
		width: 100%; /* Ensure the background scales vertically */
	}
}
