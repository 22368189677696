@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 13, 17, 23;
  --background-start-rgb: 245, 245, 245;
  --background-end-rgb: 218, 234, 255;
}
@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 25, 29, 35;
    --background-end-rgb: 10, 24, 39;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
    to bottom,
    #1a73e8,   /* Start with the main brand color */
    #3f82ee,   /* Slightly lighter with a touch of mauve */
    #5d92f3,   /* Continue the gradient with lighter shades */
    #7aa2f7,   /* Even lighter with more pronounced gradient */
    #97b2fb,   /* Lightest shade at the bottom */
    #b5c2ff    /* Final touch of mauve */
  );
}


@keyframes typing {
    from {
        width: 0;
    }
}

@keyframes blink-caret {
    50% {
        border-color: transparent;
    }
}
