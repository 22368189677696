.container {
  padding-left: 20px;
  padding-right: 20px;
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  height: 60px;
}

.logo {
  display: flex;
  flex: 1;
}

.flex {
  display: flex;
}

.itemsCenter {
  align-items: center;
}

.justifyBetween {
  justify-content: space-between;
}

.signedInStatus {
  display: flex;
  align-items: center;
}

.nojsShow {
  display: none;
}

.loading::before {
  content: "Loading...";
  display: inline-block;
}

.loaded {
  display: inline-block;
}

.notSignedInText {
  margin-right: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-top: 20px;
  margin-right: 10px;
}

.loginButton {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.logoutButton {
  background-color: #f44336; /* Red */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.flex {
    display: flex;
    align-items: center;
    gap: 1rem;
}

@media (max-width: 768px) {
    .flex {
        flex-direction: column;
    }
}

